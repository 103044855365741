import React, { FC } from "react";
import { useRouter } from "next/router";
import { useOrg } from "@/contexts/OrgContext";
import { useAuth0 } from "@auth0/auth0-react";

const Home: FC = () => {
  const router = useRouter();
  const { organizationName } = useOrg();
  const { isLoading, error } = useAuth0();
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return (
      <p className="text-red-500">
        An error has occurred, please try again later.
      </p>
    );
  }
  if (organizationName) router.push(`/${organizationName}`);
};

export default Home;
